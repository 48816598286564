.Dashboard__Card__Offer {
    width: 100%;
    position: relative;
    box-shadow: 0px 14px 31px rgba(0, 0, 0, 0.25);
    background-color: var(--offer);
    border-radius: 32px;
    overflow: hidden;
    backdrop-filter: blur(72px);
    transition: all 0.3s ease;
    margin-top: 2rem;
    outline: 2px rgba(255, 255, 255, 1) solid;
    padding: 1.6rem;
    z-index: 1;
}

.Dashboard__Card__Offer:hover {
    outline: 4px rgb(255, 255, 255) solid;
    transition: 0.3s;
    background-color: var(--offerOnHover);
}

.Dashboard__Card__Offer:hover:active {
    outline: 4px #D7FF30 solid;
    transition: 0.3s;
}

.Dashboard__Card__Content {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8;
    display: inline-flex;
}

.Dashboard__Card__Content__Title__Offer {
    color: var(--white);
    font-size: 20px;
}

.Dashboard__Card__Title__Offer {
    color: white;
    font-size: 16px;
    font-family: Plus Jakarta Sans;
    font-weight: 400;
    letter-spacing: 0.84;
    word-wrap: break-word;
    display: flex;
    background-color: #171717;
    border-radius: 4rem;
    padding: 0.1rem 0.4rem;
    outline: 3px rgba(255, 255, 255, 0.5) solid;
    stroke: var(--white);
    margin: 0rem 0.4rem;
    padding: 0.4rem 1rem;
    align-items: center;
}

.Dashboard__Card__Title__Offer__Dealer {
    display: flex;
    align-items: center;
}

.Dashboard__Card__Content__Paragraph__Offer {
    width: 100%;
    color: var(--white);
    font-size: 16px;
}

.Dashboard__Card__Offer__Content__IconText{
    display: flex;
    align-content: center;
    color: var(--white);
}


/* SLIDER BUTTONS */

.OfferButton{
    position: absolute;
    margin-left: -200px;
    margin-top: -3rem;
    transition: .5s;
    transform: scale(0.7);
    display: flex;
    opacity: 0;
  }
  
  .OfferButtonHover{
    position: absolute;
    margin-left: -50px;
    margin-top: -3rem;
    transition: .5s;
    margin-left: 0px;
    transform: scale(0.7);
    display: flex;
  
  }
  
  .OfferButton__SLidingButtons{
    background-color: var(--brand);
    width: 80px;
    height: 250px;
    border-radius: 4rem;
    color: var(--textBlack);
    border: 0.2rem solid var(--brand);
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 14px 31px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(4px); 
  }
  
  .OfferButton__SLidingButtons__Text{
    writing-mode: vertical-rl;
    text-orientation: upright;
    font-size: 22px; font-family: Plus Jakarta Sans; font-weight: 800; 
  }