/* index.css */

@import './styles/dark.css';
@import './styles/light.css';

.app.day-mode {
    @import './styles/light.css';
}

.app.night-mode {
    @import './styles/dark.css';
}