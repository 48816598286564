.brand-left-part {
  height: 600px;
  background-color: #f8f9fa;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.brand-logo-top img {
  max-width: 100%;
}

.brand-carousel {
  display: flex;
  align-items: center;
}

.logo-middle {
  margin: 0 15px;
}

.brand-right-part {
  height: 600px;
  overflow-x: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 20px;
}

.brand-content {
  min-width: 200px;
}

@media (max-width: 767.98px) {
  .brand-left-part,
  .brand-right-part {
    width: 100%;
  }
  .brand-right-part {
    margin-top: 20px;
    flex-direction: column;
    align-items: center;
  }
}

/*ProductCard1*/

.ProductCard1{ height: 532px; position: relative; background: linear-gradient(165deg, white 0%, rgba(255, 255, 255, 0) 100%); box-shadow: 0px 14px 31px rgba(0, 0, 0, 0.25); border-radius: 64px; overflow: hidden;backdrop-filter: blur(72px);transition: all 0.3s ease; margin-top: 2rem;}

.ProductCard1:hover{
    outline: 4px rgb(255, 255, 255) solid; 
    transition: 0.3s;
}
.ProductCard1:hover:active{
    outline: 4px #D7FF30 solid; 
    transition: 0.3s;
}

.ProductCard1__Content{
    left: 60px; top: 53px; position: absolute; flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 8; display: inline-flex;
}
.ProductCard1__Content__Title{
    color: #2C3811; font-size: 20px;
}

/*ProductCard2*/

.ProductCard2{ height: 532px; position: relative; background: linear-gradient(165deg, white 0%, rgba(255, 255, 255, 0) 100%); box-shadow: 0px 14px 31px rgba(0, 0, 0, 0.25); border-radius: 64px; overflow: hidden;backdrop-filter: blur(72px);transition: all 0.3s ease; margin-top: 2rem;}

.ProductCard2:hover{
    outline: 4px rgb(255, 255, 255) solid; 
    transition: 0.3s;
}
.ProductCard2:hover:active{
    outline: 4px #D7FF30 solid; 
    transition: 0.3s;
}

.ProductCard2__Content{
    left: 60px; top: 53px; position: absolute; flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 8; display: inline-flex;
}
.ProductCard2__Content__Title{
    color: #2C3811; font-size: 20px;
}

/*ProductCard3*/

.ProductCard3{ height: 532px; position: relative; background: linear-gradient(165deg, white 0%, rgba(255, 255, 255, 0) 100%); box-shadow: 0px 14px 31px rgba(0, 0, 0, 0.25); border-radius: 64px; overflow: hidden;backdrop-filter: blur(72px);transition: all 0.3s ease; margin-top: 2rem;}

.ProductCard3:hover{
    outline: 4px rgb(255, 255, 255) solid; 
    transition: 0.3s;
}
.ProductCard3:hover:active{
    outline: 4px #D7FF30 solid; 
    transition: 0.3s;
}

.ProductCard3__Content{
    left: 60px; top: 53px; position: absolute; flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 8; display: inline-flex;
}
.ProductCard3__Content__Title{
    color: #2C3811; font-size: 20px;
}