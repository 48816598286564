/* General Styling */
.text-section {
  padding: 20px;
}

.category-title-wrapper {
  display: inline-block;
  background-color: #e0e0e0;
  border-radius: 10px;
  padding: 5px 10px;
}

.big-title {
  font-size: 2rem;
  margin: 20px 0;
}

.number-list {
  list-style: decimal inside;
  padding-left: 0;
}

.image-section .image-wrapper {
  width: 100%;
  height: 300px;
  background-image: url('https://www.google.com/chrome/static/images/whats-new/128/v1/mac/lens-2x.webp');
  /* Replace with actual image URL */
  background-size: cover;
  background-position: center;
  border-radius: 40px;
}

.another-image-section .another-image-wrapper {
  width: 100%;
  height: 300px;
  background-image: url('https://www.google.com/chrome/static/images/whats-new/128/v1/mac/lens-2x.webp');
  /* Replace with actual image URL */
  background-size: cover;
  background-position: center;
  border-radius: 40px;
}

.another-text-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.another-title {
  font-size: 2rem;
  margin-top: 20px;
}

.explore-title {
  margin-top: 4rem;
  margin-bottom: 30px;
  font-size: 2rem;
}

.feature-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border-radius: 40px;
  margin-top: 20px;
  background: linear-gradient(165deg, white 0%, rgba(255, 255, 255, 0) 100%);
}

.feature-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  background: linear-gradient(165deg, white 0%, rgba(255, 255, 255, 0) 100%);
  outline: 2px solid #fff;
}


/* Image Styling within Cards */
.card-image {
  max-width: 100%;
  max-height: 100%;
  border-radius: 25px;
  /* Make the image rounded */
  object-fit: cover;
  /* Ensure the image covers the entire div without stretching */
  display: block;
  margin: 0 auto;
  /* Center the image horizontally */
}

.card-text-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}


/* Shadow styling */
.shadow {
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

/* Hover effect on Learn More */
.learn-more-wrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 1rem;
  color: #8ab4f8;
  justify-content: center;
}

.learn-more-text {
  margin-right: 5px;
  transition: transform 0.3s ease;
}

.learn-more-wrapper:hover .ArrowOutwardIcon {
  transform: translateY(-2px) translateX(2px);
  transition: transform 0.3s ease;
}

.featureButton {
  display: flex;
  justify-content: center;
}

.btn-rounded {
  border-radius: 50px;
}

.KeyboardArrowDownIcon,
.KeyboardArrowUpIcon {
  transition: transform 0.3s ease;
}

#toggle-cards-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #004a77;
  color: #c2e7ff;
  padding: 1rem 2rem;
}

.card-container .hidden {
  display: none;
}

/* Media Queries for Mobile */
@media (max-width: 768px) {
  .col-md-6 {
    order: 2;
    /* Places the text section below the image section */
  }

  .col-md-6.image-section,
  .col-md-6.another-image-section {
    order: 1;
    /* Places the image section on top on mobile */
  }
}