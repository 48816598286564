/*NAVBAR*/

.BriefNavbar{
    font-size: 28px !important;
}

.navbar{
    background-color: var(--background);;
    color: #DCDCDC !important;
    backdrop-filter: blur(10px);
    padding: 2rem 0 2rem 0 !important;
}
.navbar>.container{
    display: flex;
    align-items: center;
    justify-content: center;
}

a{
    color: #fff !important;
}

.navbar-brand{
    margin-left: 2rem;
}

.nav-item{
}

.logo{
    width: 4rem !important; 
}

#NavDropdown.Item{
    color: black !important;
}