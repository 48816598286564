.CardDisplayContainer{
    margin-top: 1rem;
}

.CardDisplay{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    transform: scale(0.8);
}

.CardDisplay__Image__1{
    width: 25rem; 
    height: 40%; 
    left: 5px;
    bottom: 0px;
    position: absolute;
}
.CardDisplay__Image__2{
    width: 20rem; 
    height: 40%; 
    left: 45px;
    bottom: 0px;
    position: absolute;
}

/*CARD BUTTON*/

.CardButtonContainer{
    transform: scale(0.8);
}

.CardButton{
    color: white; width: 100%; height: 67px; padding-left: 124px; padding-right: 124px; padding-top: 16px; padding-bottom: 16px; background: rgba(255, 255, 255, 0.50); box-shadow: 0px 14px 31px rgba(0, 0, 0, 0.25); border-radius: 36px; overflow: hidden; backdrop-filter: blur(4px); justify-content: center; align-items: center; gap: 10px; display: inline-flex;  transition: all 0.3s ease;
}

.WelcomePageButton{
    margin-top: 2rem;
}

.CardButton:hover{
    background: #ffffff;
    color: black; 
    transition: 0.3s;
}
.CardButton:hover:active{
    outline: 4px #D7FF30 solid; 
    transition: 0.3s;
}   


.CardButton__Text{
    font-size: 22px; font-family: Plus Jakarta Sans; font-weight: 800; letter-spacing: 2.66px; word-wrap: break-word; display: flex; align-items: center;
}

/*CARD 1*/

.Card1{width: 100%; height: 532px; position: relative; background: linear-gradient(165deg, white 0%, rgba(255, 255, 255, 0) 100%); box-shadow: 0px 14px 31px rgba(0, 0, 0, 0.25); border-radius: 64px; overflow: hidden;backdrop-filter: blur(72px);transition: all 0.3s ease; margin-top: 2rem;}

.Card1:hover{
    outline: 4px rgb(255, 255, 255) solid; 
    transition: 0.3s;
}
.Card1:hover:active{
    outline: 4px #D7FF30 solid; 
    transition: 0.3s;
}

.Card1__Content{
    left: 60px; top: 53px; position: absolute; flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 8; display: inline-flex;
}
.Card1__Content__Title{
    color: #2C3811; font-size: 20px;
}
.Card2__Content__Title{
    color: var(--white); font-size: 20px;
}

/*CARD 2*/

.Card2{
    width: 100%; height: 532px; position: relative; background: linear-gradient(165deg, #9EC208 0%, rgba(0, 0, 0, 0) 100%); box-shadow: 0px 14px 31px rgba(0, 0, 0, 0.25); border-radius: 64px; overflow: hidden; backdrop-filter: blur(72px); transition: 0.3s; margin-top: 2rem;
}
.Card2:hover{
    outline: 4px rgb(255, 255, 255) solid; 
    transition: 0.3s;
}
.Card2:hover:active{
    outline: 4px #D7FF30 solid; 
    transition: 0.3s;
}

/*CARD 3*/

.Card3{
    width: 100%; height: 520px; position: relative; transition: all 0.3s ease;margin-top: 2rem;
}

.Card3__Alignment{
    display: flex;
    flex-direction: column;
}

#CardButton{
    margin-top: -4rem;
}

.Card3__1{
    width: 100%; height: 250px; left: 0px; top: 0px; background: linear-gradient(167deg, black 0%, rgba(47.81, 47.81, 47.81, 0) 100%); box-shadow: 0px 14px 31px rgba(0, 0, 0, 0.25); border-radius: 64px; overflow: hidden; backdrop-filter: blur(72px)
}
.Card3__1:hover{
    outline: 4px rgb(255, 255, 255) solid; 
    transition: 0.3s;
}
.Card3__1:hover:active{
    outline: 4px #D7FF30 solid; 
    transition: 0.3s;
}

.Card3__2{
    width: 100%; height: 250px; left: 0px; top: 282px; background: linear-gradient(166deg, #00262D 0%, rgba(0, 38, 45, 0.50) 100%); box-shadow: 0px 14px 31px rgba(0, 0, 0, 0.25); border-radius: 64px; overflow: hidden; backdrop-filter: blur(72px); margin-top: 32px;
}
.Card3__2:hover{
    outline: 4px rgb(255, 255, 255) solid; 
    transition: 0.3s;
}
.Card3__2:hover:active{
    outline: 4px #D7FF30 solid; 
    transition: 0.3s;
}

@media only screen and (max-width: 767px) {
    
    .Card1{
        margin-top: -28rem;
    }

    .CardDisplay{
        transform: scale(0.6);
    }

}

@media only screen and (min-width: 767px) and (max-width: 992px) {
    
    .Card1{
        margin-top: -10rem;
    }

}