.applyBody {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    color: var(--white);
    background-image: url('../../assets/images/bgzone.png');
    background-size: 30%;
    background-position: left center; /* Set background image to the left horizontally and center vertically */
    background-repeat: no-repeat;
    opacity: 0.8; /* Adjust the opacity as needed (0.0 to 1.0) */
}

@media only screen and (max-width: 767px) {
    .applyBody {
        background-image: none; /* Remove background image on mobile */
        opacity: 1; /* Set opacity to 1 on mobile */
    }
  
    input {
        width: 100%; /* Make the input fields take up 100% width on mobile */
    }
}

.centered-container {
    text-align: left; /* Align text to the left */
}

.formTitleAlignment {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: Plus Jakarta Sans;
    font-weight: 600;
    letter-spacing: 2.66px;
    text-shadow: 0px 14px 31px rgba(0, 0, 0, 1);
}

.logoApply {
    width: 100px;
    height: auto;
    margin-right: 10px;
    margin-top: -1rem;
}

/* Style for the form inputs */
.beDealerInput {
    height: 4rem;
    width: 100%;
    box-sizing: border-box; /* Include padding and border in the width */
    margin-bottom: 10px; /* Adjust spacing between input fields */
    border-style: none;
    padding: 0.2rem 0.5rem;
    background: linear-gradient(165deg, white 0%, rgba(255, 255, 255, 0) 100%);
    box-shadow: 0px 14px 31px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    overflow: hidden;
    backdrop-filter: blur(72px);
    transition: all 0.3s ease;
}

.beDealerInput:hover {
    outline: 4px rgb(255, 255, 255) solid;
    transition: 0.3s;
}

.beDealerInput:hover:active {
    outline: 4px #D7FF30 solid;
    transition: 0.3s;
}

label {
    font-family: Plus Jakarta Sans;
    font-weight: 400;
    letter-spacing: 1px;
    font-size: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

h1 {
    margin-bottom: 20px; /* Adjust spacing below the heading */
}

.beDealerButton{
    height: 4rem;
    width: 100%;
    color: var(--white);
    border-style: none;
    padding: 0.2rem 0.5rem;
    background: linear-gradient(165deg, white 0%, rgba(255, 255, 255, 0) 100%);
    box-shadow: 0px 14px 31px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    overflow: hidden;
    backdrop-filter: blur(72px);
    transition: all 0.3s ease;
    font-family: Plus Jakarta Sans;
    font-weight: 600;
    letter-spacing: 2.66px;
    font-size: 1.4rem;
}
.beDealerButton:hover {
    outline: 4px rgb(255, 255, 255) solid;
    transition: 0.3s;
    color: var(--background);
    background: linear-gradient(165deg, white 1000%, rgba(255, 255, 255, 0) 100%);
}

.beDealerButton:hover:active {
    outline: 4px #D7FF30 solid;
    transition: 0.3s;
    background: linear-gradient(165deg, white 0%, rgba(255, 255, 255, 0) 100%);
}