.sidenav {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 1;
}

.logo_title {
  display: flex;
  flex-direction: row;
  z-index: 1;
  align-content: center;
  align-items: center;
  margin: -5rem 0 -5rem 0;
}

.navbar-brand {
  color: #fff !important;
  font-size: 2rem !important;
}

.sidenav__logo {
  width: 80px;
  margin: 25px;
}

.sidenav__buttons {
  display: flex;
  flex-direction: column;
}

.sidenav__button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: white;
  background: 0;
  border: 0;
  margin: 5px 10px 5px 10px;
  padding: 10px 15px 10px 15px;
  border-radius: 4rem;
  width: 100%;
  transition: 0.3s;
  border: 4px solid rgba(255, 255, 255, 0);
}

.sidenav__button__margin {
  margin: 5px 10px 5px 10px;
}

.sidenav__button__returnmargin {
  margin: 5px 10px 5px 0rem;
}

.sidenav__button__selected {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: white;
  background: 0;
  border: 0;
  margin: 5px 10px 5px 10px;
  padding: 10px 15px 10px 15px;
  width: 100%;
  border-radius: 4rem;
  border: 4px solid rgba(255, 255, 255, 0.50);
  background: linear-gradient(355deg, rgba(158, 194, 8, 0.75) -22.62%, rgba(255, 255, 255, 0.50) 192.8%);
  box-shadow: 0px 14px 31px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(36px);
  transition: 0.3s;
}

.sidenav__button__selected:hover {
  cursor: pointer;
  border: 4px solid rgb(255, 255, 255);
  transition: 0.3s;
}

.sidenav__button:hover {
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.128);
  transition: 0.3s;
}

.sidenav__button>span {
  font-size: 18px;
  font-weight: bolder;
  margin-left: 10px;
}

.sidenav__button__selected>span {
  font-size: 18px;
  font-weight: bolder;
  margin-left: 10px;
}

.sidenav__more {
  position: fixed;
  bottom: 3px;
  left: 0;
}

.logout__button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: white;
  background: 0;
  border: 0;
  margin: 5px 10px 5px 10px;
  padding: 10px 15px 10px 15px;
  border-radius: 4rem;
  width: 100%;
  transition: 0.3s;
  border: 4px solid rgba(255, 255, 255, 0);
}

.logout__button:hover {
  cursor: pointer;
  background-color: rgba(202, 18, 18, 0.424);
  transition: 0.3s;
}

.SelectionDeleteAlignment__Text {
  font-size: 1.4rem;
}

.sidenav__minibuttons {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  margin-top: 2rem;
}