:root {
  --background: #00262D;
  --whiteBackground: #F2F2F2F2;
  --white: #F2F2F2F2;
  --brand: #D7FF30;
  --offer: #ffffff00;
  --offerOnHover: #51929d40;
  --cardBgDashboard: #f2852c9e;
  --cardBgDashboardOnHover: #F2862C;
  --slightlyGray: #5B5B5B;
  --textBlack: #1B1E24;
}

body {
  background-color: var(--background) !important;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loader {
  border: 8px solid rgba(0, 0, 0, 0.1);
  border-top: 8px solid #D7FF30;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}