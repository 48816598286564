.CardDisplayProfilesContainer{
    margin-top: 1rem;
}

.CardDisplayProfiles{
    display: flex;
    flex-direction: row;
    transform: scale(0.8);
    align-items: center;
}

.CardDisplayProfiles__Image__Profiles{
    width: 18rem; 
    left: 0px;
    bottom: 6rem;
    position: absolute;
}

.CardDisplayProfiles__Text__Profiles{
    bottom: 12rem;
    right: 0px;
    position: absolute;
}
/*CARD BUTTON*/

.CardButtonContainer{
    transform: scale(0.8);
}

.CardButton{
    color: white; width: 100%; height: 67px; padding-left: 124px; padding-right: 124px; padding-top: 16px; padding-bottom: 16px; background: rgba(255, 255, 255, 0.50); box-shadow: 0px 14px 31px rgba(0, 0, 0, 0.25); border-radius: 36px; overflow: hidden; backdrop-filter: blur(4px); justify-content: center; align-items: center; gap: 10px; display: inline-flex;  transition: all 0.3s ease;
}

.WelcomePageButton{
    margin-top: 2rem;
}

.CardButton:hover{
    background: #ffffff;
    color: black; 
    transition: 0.3s;
}
.CardButton:hover:active{
    outline: 4px #D7FF30 solid; 
    transition: 0.3s;
}   


.CardButton__Text{
    font-size: 22px; font-family: Plus Jakarta Sans; font-weight: 800; letter-spacing: 2.66px; word-wrap: break-word; display: flex; align-items: center;
}

/*CARD PROFILE*/

.CardProfiles{width: 100%; height: 532px; position: relative; background: var(--white); box-shadow: 0px 14px 31px rgba(0, 0, 0, 0.25); border-radius: 64px; overflow: hidden;backdrop-filter: blur(72px);transition: all 0.3s ease;}

.CardProfiles:hover{
    outline: 4px rgb(255, 255, 255) solid; 
    transition: 0.3s;
}
.CardProfiles:hover:active{
    outline: 4px #D7FF30 solid; 
    transition: 0.3s;
}

/*SWITCHER*/

.CardProfiles__Switcher{

}

.CardProfiles__Switcher__Button__Active{
    background-color: var(--brand);
    width: 80px;
    height: 250px;
    border-radius: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 14px 31px rgba(0, 0, 0, 0.25); 
    color: var(--background);
}
.CardProfiles__Switcher__Button{
    background-color: var(--background) 30%;
    width: 80px;
    height: 250px;
    border-radius: 4rem;
    color: var(--brand);
    border: 0.2rem solid var(--brand);
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 14px 31px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(4px); 
}

.CardProfiles__Switcher__Text{
    writing-mode: vertical-rl;
    text-orientation: upright;
    font-size: 22px; font-family: Plus Jakarta Sans; font-weight: 800; 
}

/*SWITCHER CONTENT*/

.CardDisplayProfiles__Content__Icon{
    color: var(--white);
    padding: 1.4rem;
    border-radius: 2rem;
    background: rgb(255, 255, 255, 0.5);
    stroke: var(--white);
    filter: drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.2));
    transition: 0.3s;
    width: 8rem;
    height: 8rem;
    margin-top: 4rem;
}
.CardDisplayProfiles__Content__Icon__Logo{
    color: var(--white);
    padding: 1.4rem;
    border-radius: 2rem;
    background: rgb(255, 255, 255, 0.5);
    stroke: var(--white);
    filter: drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.2));
    transition: 0.3s;
    width: 8rem;
    height: 8rem;
    margin-top: 4rem;
}
.CardDisplayProfiles__Content__Icon:hover{
    outline: 6px var(--white) solid; 
    transition: 0.3s;
}

.CardDisplayProfiles__Content{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: nowrap;
    margin-top: 4rem;
}

.CardDisplayProfiles__Content__Texts{
    margin-left: 2rem;
}

.CardDisplayProfiles__Content__Title{
    color: var(--white); 
    font-size: 2rem; 
    font-family: 'Plus Jakarta Sans';
    font-weight: 600; 
    word-wrap: break-word;
}

.CardDisplayProfiles__Content__Description{
    color: var(--white); 
    font-size: 1.6rem; 
    font-family: 'Plus Jakarta Sans';
    font-weight: 400; 
    word-wrap: break-word;
}

.CardDisplayProfiles__ActionButton{
    height: 4rem;
    width: 100%;
    color: var(--textBlack);
    border-style: none;
    padding: 0.2rem 0.5rem;
    background: linear-gradient(165deg, #00d7ff 0%, rgb(215 255 49) 100%);
    box-shadow: 0px 14px 31px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    overflow: hidden;
    backdrop-filter: blur(72px);
    transition: all 0.3s ease;
    font-family: Plus Jakarta Sans;
    font-weight: 600;
    letter-spacing: 2.66px;
    font-size: 1.4rem;
    margin-top: 2rem;
}
.CardDisplayProfiles__ActionButton:hover {
    outline: 4px rgb(255, 255, 255) solid;
    transition: 0.3s;
    color: var(--textBlack);
    background: linear-gradient(165deg, white 1000%, rgba(255, 255, 255, 0) 100%);
}

.CardDisplayProfiles__ActionButton:hover:active {
    outline: 4px #D7FF30 solid;
    transition: 0.3s;
    background: linear-gradient(165deg, white 1000%, rgba(255, 255, 255, 0) 100%);
}

@media only screen and (min-width: 767px) and (max-width: 992px) {
    
    .CardDisplayProfilesContainer{
        margin-top: -6rem;
    }
        
}
