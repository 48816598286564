.dashboard {
  display: flex;
  flex-direction: row;
}

.dashboard__left {
  flex: 0.7;
}

.dashboard__right {
  flex: 0.3;
}

.logo_title {
  display: flex;
  flex-direction: row;
  z-index: 1;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin: -5rem 0 -5rem 0;
}

.Dashboard__PrimaryCard {
  width: 100%;
  position: relative;
  box-shadow: 0px 14px 31px rgba(0, 0, 0, 0.25);
  background-color: var(--cardBgDashboardOnHover);
  border-radius: 32px;
  overflow: hidden;
  backdrop-filter: blur(72px);
  transition: all 0.3s ease;
  margin-top: 2rem;
  outline: 2px rgba(255, 255, 255, 1) solid;
  padding: 1rem;
  z-index: 1;
}

.Dashboard__PrimaryCard:hover {
  outline: 4px rgb(255, 255, 255) solid;
  background-color: var(--cardBgDashboardOnHover);
  transition: 0.3s;
}

.Dashboard__Card {
  width: 100%;
  position: relative;
  box-shadow: 0px 14px 31px rgba(0, 0, 0, 0.25);
  background-color: var(--cardBgDashboard);
  border-radius: 32px;
  overflow: hidden;
  backdrop-filter: blur(72px);
  transition: all 0.3s ease;
  margin-top: 2rem;
  outline: 2px rgba(255, 255, 255, 1) solid;
  padding: 1rem;
}

.Dashboard__Card:hover {
  outline: 4px rgb(255, 255, 255) solid;
  background-color: var(--cardBgDashboardOnHover);
  transition: 0.3s;
}

.Dashboard__Card:hover:active {
  outline: 4px #D7FF30 solid;
  transition: 0.3s;
}

.Dashboard__Card__Content {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8;
  display: inline-flex;
}

.Dashboard__Card__Content__Title {
  color: var(--white);
  font-size: 20px;
}

.Stages__Items {
  width: 60rem;
  height: 10rem;
}

.Stages__Item {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.Stages__Title {
  color: var(--white);
  font-size: 1.5rem;
  margin-top: 1rem;
}

.Stages__Icon {
  color: var(--white);
  width: 3rem !important;
  height: 3rem !important;
  background-color: rgba(85, 204, 255, 1);
  border-radius: 100%;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: 4px var(--white) solid;
}

.Stages__Hr {
  border: 2px solid var(--white);
  opacity: 1;
  border-radius: 5px;
  width: 5rem;
  margin: 1.3rem 0 0 -2rem;
}

.Dashboard__Card__Content__Inner {
  display: flex;
  justify-content: space-between;
}

.Dashboard__Card__Content__Inner__DisplayFlex {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.Dashboard__Card__Content__Illustration {
  width: 40%;
}

.Dashboard__Card__Title {
  color: white;
  font-size: 16px;
  font-family: Plus Jakarta Sans;
  font-weight: 400;
  letter-spacing: 0.84;
  word-wrap: break-word;
  display: flex;
  background-color: #171717;
  border-radius: 4rem;
  padding: 0.1rem 0.4rem;
  outline: 3px rgba(255, 255, 255, 0.5) solid;
  stroke: var(--white);
  margin: 0rem 0.4rem;
  padding: 0.4rem 1rem;
  align-items: center;
}

.Dashboard__Card__Content__Paragraph {
  color: #171717;
  font-size: 30;
}

.sliderButton {}

.sliderButton:hover {}

/* SLIDER BUTTONS */

.Dashboard__PrimaryCard__Button {
  position: absolute;
  margin-left: -100px;
  margin-top: -3rem;
  transition: .5s;
  transform: scale(0.7);

}

.Dashboard__PrimaryCard__ButtonHover {
  position: absolute;
  margin-left: -50px;
  margin-top: -3rem;
  transition: .5s;
  margin-left: 0px;
  transform: scale(0.7);

}

.Dashboard__PrimaryCard__SlidingButtons {
  background-color: var(--cardBgDashboardOnHover);
  width: 80px;
  height: 250px;
  border-radius: 4rem;
  color: var(--textBlack);
  border: 0.2rem solid var(--textBlack);
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 14px 31px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(4px);
}

.Dashboard__PrimaryCard__SlidingButtons__Text {
  writing-mode: vertical-rl;
  text-orientation: upright;
  font-size: 22px;
  font-family: Plus Jakarta Sans;
  font-weight: 800;
}