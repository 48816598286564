.table-responsive {
  overflow-x: auto;
}

.sticky-column {
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  left: 0;
  background-color: var(--background); /* Ensures the fixed column has the same background as the table */
  z-index: 1;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2); /* Add shadow to the column */
}


th, td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd !important;
}

tr:hover {background-color: coral;}