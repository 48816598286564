.footer-section {
    height: 100vh;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    padding: 0 0 0 0;
}

.form-section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* Replace with your actual image URL */
    background-position: center;
}

.form-control{
    max-height: 200px;
}

.contact-info-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: linear-gradient(140deg, #b730ff6f 0%, rgba(255, 255, 255, 0) 100%);
    /* Replace with your desired color */
    color: #fff;
    /* Ensures text contrast against the dark background */
}

.form-title,
.contact-title,
.info-title {
    font-size: 2rem;
    font-weight: bold;
}

.form-floating>label {
    font-size: 18px;
    transition: all 0.3s ease;
}

.form-floating>input:focus~label,
.form-floating>textarea:focus~label,
.form-floating>input:not(:placeholder-shown)~label,
.form-floating>textarea:not(:placeholder-shown)~label {
    font-size: 12px;
    transform: translateY(-150%);
    color: white !important;
}

.form-control {
    background-color: #ffffff00;
    border-radius: 0;
    border: none;
    border-bottom: 2px solid rgba(255, 255, 255, 0.5);
    color: white;
}

.form-control:focus {
    background-color: #ffffff00;
    border-bottom: 2px solid rgba(255, 255, 255, 1);
    color: white;
}

.form-floating>label:after {
    background-color: #ffffff00 !important;
}

.contact-subtitle,
.info-subtitle {
    font-weight: bold;
}

.contact-image {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-grow: 1;
}

.contact-image img {
    max-width: 100%;
    height: auto;
}

@media (max-width: 767.98px) {
    .footer-section {
        flex-direction: column;
    }

    .form-section,
    .contact-info-section {
        height: 100vh;
        width: 100%;
    }

    .contact-info-section {
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .contact-image {
        justify-content: center;
        align-items: center;
    }

    .contact-image img {
        margin-top: 20px;
    }
}