.WhatWeDo__NavgiatonCard{
    width: 100%;
    color: var(--textBlack);
    border-style: none;
    padding: 2rem;
    outline: 2px rgb(255, 255, 255, 0.5) solid;
    box-shadow: 0px 14px 31px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    overflow: hidden;
    backdrop-filter: blur(72px);
    transition: all 0.3s ease;
    font-family: Plus Jakarta Sans;
    font-weight: 600;
    letter-spacing: 2.66px;
    font-size: 1.4rem;
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
}

.WhatWeDo__NavgiatonCard:hover {
    outline: 2px rgb(255, 255, 255) solid;
    transition: 0.3s;
    color: var(--textBlack);
    background: linear-gradient(165deg, rgba(255, 255, 255, 0.5) 1000%, rgba(255, 255, 255, 0) 100%);
}

.WhatWeDo__NavgiatonCard:hover:active {
    outline: 4px #D7FF30 solid;
    transition: 0.3s;
    background: linear-gradient(165deg, white 1000%, rgba(255, 255, 255, 0) 100%);
}
.WhatWeDo__NavgiatonCard__Icon{
    color: var(--white);
    width: 4rem !important;
    height: 4rem !important;
    background-color: var(--textBlack);
    border-radius: 100%;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.WhatWeDo__NavgiatonCard__Content{
    margin-top: 2rem;
    color: var(--white);
}

/* WhatWeDo__Content */

.WhatWeDo__Content{
    width: 100%;
    color: var(--textBlack);
    border-style: none;
    padding: 2rem;
    outline: 2px rgb(255, 255, 255, 0.5) solid;
    box-shadow: 0px 14px 31px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    overflow: hidden;
    backdrop-filter: blur(72px);
    transition: all 0.3s ease;
    font-family: Plus Jakarta Sans;
    font-weight: 600;
    letter-spacing: 2.66px;
    font-size: 1.4rem;
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
}
