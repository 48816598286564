/*CARDS BUTTON*/
.CreateProject{
    width: 100%; height: 34rem; padding-left: 43px; padding-right: 43px; padding-top: 52px; padding-bottom: 52px; background: rgba(255, 255, 255, 0.25); box-shadow: 0px 21.493486404418945px 47.592716217041016px rgba(0, 0, 0, 0.25); border-radius: 32px; overflow: hidden; backdrop-filter: blur(110.54px); transition: 0.3s;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.CreateProject:hover{
    outline: 4px rgb(255, 255, 255) solid; 
    background: rgba(215, 255, 48, 0.25);
    transition: 0.3s;
}
.CreateProject:hover:active{
    outline: 4px #D7FF30 solid; 
    transition: 0.3s;
}

.CreateProjectAlignment{
    height: 360px;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: space-evenly;
}

/*CARD BUTTON*/

.CreateProjectButton {
    color: var(--white);
    padding: 0.6rem;
    border-radius: 9999px;
    background: rgb(255, 255, 255, 0.5);
    stroke: var(--white);
    filter: drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.2));
    transition: 0.3s;
    cursor: pointer;

}

.CreateProjectButton:hover{
    color: var(--brand); 
    outline: 6px var(--brand) solid; 
    transition: 0.3s;
    stroke: var(--brand);
    filter: drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.4));
    transition: 0.3s;
}

/*PROJECT CARD GRID*/
.projectsCardGrid{
    margin-top: -1rem;
}

.projectsCard__Col{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.projectsCard__Content{
    margin-top: 1rem;
    display: flex;
    align-items: center;
    flex-direction: row;
}

.projectsCard__Logo{
    filter: drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.4))
}

.projectsCard__Info{
    color: white;
    font-size: 20px; 
    font-family: Plus Jakarta Sans;
    font-weight: 400; 
    letter-spacing: 0.84; 
    word-wrap: break-word;
    display: flex;
    background-color: #171717;
    border-radius: 4rem;
    padding: 0.1rem 0.4rem;
    outline: 3px rgba(255, 255, 255, 0.5) solid; 
    stroke: var(--white);
    margin: 0.4rem 0;
    padding: 0.4rem 1rem;
}

.projectsCard__Info__Icon{
    margin-top: 0.25rem;
    margin-right: 1rem;
}

.projectsCard__Info__Text{
    margin-bottom: 0rem;
}

/*DEALER CARD*/
.Dealer{
    width: 40%;
    height: 16rem;
    border-radius: 32px;
    background: linear-gradient(165deg, #9EC208 0%, rgba(255, 255, 255, 0.5) 100%); box-shadow: 0px 14px 31px rgba(0, 0, 0, 0.25); 
    margin: 1rem;
    transition: 0.3s;
    cursor: pointer;
}
.Dealer:hover{
    background: linear-gradient(165deg, #9EC208 0%, rgba(255, 255, 255, 0.75) 100%); box-shadow: 0px 14px 31px rgba(0, 0, 0, 0.25); 
    transition: 0.3s;
    outline: 4px rgb(255, 255, 255) solid; 
}