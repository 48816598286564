.release-item {
    border: 1px solid #ddd;
    padding: 20px;
    margin: 20px 0;
    color: var(--white);

    position: relative;
    box-shadow: 0px 14px 31px rgba(0, 0, 0, 0.25);
    background-color: var(--offer);
    border-radius: 32px;
    overflow: hidden;
    backdrop-filter: blur(72px);
    transition: all 0.3s ease;
    margin-top: 2rem;
    outline: 2px rgba(255, 255, 255, 1) solid;

    padding: 1.6rem;
    z-index: 1;
}


.release-item:hover {
    outline: 4px rgb(255, 255, 255) solid;
    transition: 0.3s;
    background-color: var(--offerOnHover);
}

.assets{
    border-top: 1px solid #ddd;
    padding: 10px 0;
}

.assets-dropdown {
    position: relative;
}

.assets-list {
    display: none;
    position: absolute;
    background: #fff;
    border: 1px solid #ddd;
    padding: 10px;
}

.assets-dropdown .assets-list {
    display: block;
}

.assets-dropdown-toggler {
    color: white;
    padding: 4px 0px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    margin: 4px 2px;
    cursor: pointer;
}

.asset-item {
    margin-bottom: 10px;
}

.replies{

}

.reply-item {
    border-top: 1px solid #ddd;
    padding: 10px 0;
}

.reply-image {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
}

/* SLIDER BUTTONS */

.UpdateButton {
    position: absolute;
    margin-left: -100px;
    margin-top: -3rem;
    transition: .5s;
    transform: scale(0.7);
    opacity: 0;
}

.UpdateButtonHover {
    position: absolute;
    margin-left: -50px;
    margin-top: -3rem;
    transition: .5s;
    margin-left: 0px;
    transform: scale(0.7);

}

.UpdateButton__SLidingButtons {
    background-color: var(--brand);
    width: 80px;
    height: 80px;
    border-radius: 4rem;
    color: var(--textBlack);
    border: 0.2rem solid var(--brand);
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 14px 31px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(4px);
    padding-top: 2px;
    transition: .2s;
}

.UpdateButton__SLidingButtons:hover {
    background-color: var(--textBlack);
    color: var(--brand);
    transition: .2s;
}

.UpdateButton__SLidingButtons:hover:active {
    background-color: var(--brand);
    color: var(--textBlack);
    transition: .2s;
}

.UpdateButton__SLidingButtons__Text {
    font-size: 22px;
    font-family: Plus Jakarta Sans;
    font-weight: 800;
}