/*CARDS BUTTON*/
.OptionCardMain1{
    width: 100%; height: 500px; padding-left: 43px; padding-right: 43px; padding-top: 52px; padding-bottom: 52px; background: rgba(73, 73, 73, 0.5); box-shadow: 0px 21.493486404418945px 47.592716217041016px rgba(0, 0, 0, 0.25); border-radius: 64px; overflow: hidden; backdrop-filter: blur(110.54px); flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 10px; display: inline-flex; transition: 0.3s;
}
.OptionCardMain1:hover{
    outline: 4px rgb(255, 255, 255) solid; 
    background: rgba(22, 140, 205, 0.5);
    transition: 0.3s;
}
.OptionCardMain1:hover:active{
    outline: 4px #D7FF30 solid; 
    transition: 0.3s;
}

.OptionCardMain2{
    width: 100%; height: 500px; padding-left: 43px; padding-right: 43px; padding-top: 52px; padding-bottom: 52px; background: rgba(73, 73, 73, 0.5); box-shadow: 0px 21.493486404418945px 47.592716217041016px rgba(0, 0, 0, 0.25); border-radius: 64px; overflow: hidden; backdrop-filter: blur(110.54px); flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 10px; display: inline-flex;
}
.OptionCardMain2:hover{
    outline: 4px rgb(255, 255, 255) solid; 
    background: rgba(240, 75, 38, 0.50); 
    transition: 0.3s;
}
.OptionCardMain2:hover:active{
    outline: 4px #D7FF30 solid; 
    transition: 0.3s;
}


.OptionCardMain3{
    width: 100%; height: 420px; padding-left: 43px; padding-right: 43px; padding-top: 52px; padding-bottom: 52px; background: rgba(73, 73, 73, 0.5); box-shadow: 0px 21.493486404418945px 47.592716217041016px rgba(0, 0, 0, 0.25); border-radius: 64px; overflow: hidden; backdrop-filter: blur(110.54px); flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 10px; display: inline-flex;
}
.OptionCardMain3:hover{
    outline: 4px rgb(255, 255, 255) solid; 
    background: rgba(84, 183, 169, 0.50); 
    transition: 0.3s;
}
.OptionCardMain3:hover:active{
    outline: 4px #D7FF30 solid; 
    transition: 0.3s;
}


.OptionCardMain4{
    width: 100%; height: 420px; padding-left: 43px; padding-right: 43px; padding-top: 52px; padding-bottom: 52px; background: rgba(73, 73, 73, 0.5); box-shadow: 0px 21.493486404418945px 47.592716217041016px rgba(0, 0, 0, 0.25); border-radius: 64px; overflow: hidden; backdrop-filter: blur(110.54px); flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 10px; display: inline-flex;
}
.OptionCardMain4:hover{
    outline: 4px rgb(255, 255, 255) solid; 
    background: rgba(108, 115, 143, 0.50); 
    transition: 0.3s;
}
.OptionCardMain4:hover:active{
    outline: 4px #D7FF30 solid; 
    transition: 0.3s;
}


.OptionCardMain5{
    width: 100%; height: 420px; padding-left: 43px; padding-right: 43px; padding-top: 52px; padding-bottom: 52px; background: rgba(73, 73, 73, 0.5); box-shadow: 0px 21.493486404418945px 47.592716217041016px rgba(0, 0, 0, 0.25); border-radius: 64px; overflow: hidden; backdrop-filter: blur(110.54px); flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 10px; display: inline-flex;
}
.OptionCardMain5:hover{
    outline: 4px rgb(255, 255, 255) solid; 
    background: rgba(161, 26, 98, 0.50); 
    transition: 0.3s;
}
.OptionCardMain5:hover:active{
    outline: 4px #D7FF30 solid; 
    transition: 0.3s;
}

.OptionCardSub{
    width: 100%; height: 420px; padding-left: 43px; padding-right: 43px; padding-top: 52px; padding-bottom: 52px; box-shadow: 0px 21.493486404418945px 47.592716217041016px rgba(0, 0, 0, 0.25); overflow: hidden; backdrop-filter: blur(110.54px); flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 10px; display: inline-flex; margin-top: 2rem;
}
.OptionCardSub:hover{
    outline: 4px rgb(255, 255, 255) solid; 
    background: rgba(18, 18, 18, 0.5); 
    transition: 0.3s;
}
.OptionCardSub:hover:active{
    outline: 4px #D7FF30 solid; 
    transition: 0.3s;
}

.OptionCardAlignment{
    margin-top: 1.8rem;
}

.OptionCardSub__Unselected{
    background: #d6ff307f; border-radius: 32px; overflow: hidden; transition: 0.3s; outline: 4px #D7FF30 solid; transition: 0.3s;
}
.OptionCardSub__Unselected:hover{
    outline: 4px #D7FF30 solid; 
    background: #d6ff307f; 
    transition: 0.3s;
}
.OptionCardSub__Selected{
    background: rgba(73, 73, 73, 0.5); border-radius: 64px; overflow: hidden; 
}
.OptionCardSub__Selected:hover{
    outline: 4px rgb(255, 255, 255) solid; 
    background: rgba(18, 18, 18, 0.5); 
    transition: 0.3s;
}
/*CARD BUTTON*/

.CardButton{
    padding: 2rem 2rem;
    color: white; width: 100%; background: rgba(255, 255, 255, 0.50); box-shadow: 0px 14px 31px rgba(0, 0, 0, 0.25); border-radius: 36px; overflow: hidden; backdrop-filter: blur(4px); transition: all 0.3s ease;
}

.CardButton:hover{
    background: #ffffff;
    color: black; 
    transition: 0.3s;
}
.CardButton:hover:active{
    outline: 4px #D7FF30 solid; 
    transition: 0.3s;
}   

.CardButton__Selected{
    padding: 2rem 2rem;
    color: var(--brand); width: 100%; background: rgba(9, 174, 48, 0.5); box-shadow: 0px 14px 31px rgba(0, 0, 0, 0.25); border-radius: 36px; overflow: hidden; backdrop-filter: blur(4px); transition: all 0.3s ease;
}
.CardButton__Selected:hover{
    background: #ffffff;
    color: black; 
    transition: 0.3s;
}
.CardButton__Selected:hover:active{
    outline: 4px #D7FF30 solid; 
    transition: 0.3s;
}   
.CardButton__Text{
    font-size: 22px; font-family: Plus Jakarta Sans; font-weight: 800; letter-spacing: 2.66px;
    display: flex;
    justify-content: center;
    align-items: center;
}

/*LAST STEP*/

.StepContent{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    font-family: Plus Jakarta Sans; word-wrap: break-word; color: white;
}

.StepContent__Text {
    text-align: left;
    width: 80%;
    font-size: 52px;
    text-shadow: 0px 0px 20px #17171775;
}

.StepContent__Text__White{
    color: white;
}
.StepContent__Text > h1{
    width: 100%;
}
.StepContent__Text > h2{
    font-weight: 200 !important;
}

/*LAST STEP BUTTONS*/

.StepContent__Select{
    display: flex;
}

.StepContent__MicroSelect{
    color: white;
    font-size: 20px; 
    font-family: Plus Jakarta Sans;
    font-weight: 400; 
    letter-spacing: 0.84; 
    word-wrap: break-word;
    display: flex;
    background-color: #171717;
    border-radius: 4rem;
    padding: 0.1rem 0.4rem;
    outline: 3px rgba(255, 255, 255, 0.5) solid; 
    stroke: var(--white);
    margin: 0rem 0.4rem;
    padding: 0.4rem 1rem;
    cursor: pointer;
}

.StepContent__MicroSelect__Active{
    color: white;
    font-size: 20px; 
    font-family: Plus Jakarta Sans;
    font-weight: 400; 
    letter-spacing: 0.84; 
    word-wrap: break-word;
    display: flex;
    background-color: #171717;
    border-radius: 4rem;
    padding: 0.1rem 0.4rem;
    outline: 3px rgba(11, 220, 0, 0.5) solid; 
    stroke: var(--white);
    margin: 0rem 0.4rem;
    padding: 0.4rem 1rem;
    cursor: pointer;
}