.WelcomeOzgurluguZorla {
    background-color: var(--whiteBackground) !important;
    background-repeat: no-repeat;
    background-position: center top;
    background-size: 120%;
    background-attachment: fixed;
}

.hero {
    text-align: center;
    padding: 20px;
}

.logo {
    max-width: 150px;
}

.title {
    color: #01272E;
    font-size: 24px;
}

.content {
    display: flex;
    flex-direction: row;
    margin: 20px;
}

.form-container {
    flex: 1;
    background-color: #FFFFFF;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}


form input,
form textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

form button {
    background-color: #9BC000;
    color: #FFFFFF;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

form button:hover {
    background-color: #7a9e00;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .content {
        flex-direction: column;
    }

    .socials {
        margin-bottom: 20px;
    }
}