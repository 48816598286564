.AuthModal{
  color: var(--white);
  background-color: var(--background) !important;
}

.AuthModalHeader{
  background-color: var(--white) 0% !important;
  background: linear-gradient(90deg, #9EC208 40%, rgba(0, 0, 0, 0) 100%); 
  color: var(--background);
  box-shadow: 0px 14px 31px rgba(0, 0, 0, 0.25) !important; 
}


.saniba_modal:hover{
  border: 9.01px rgb(255, 255, 255) solid; 
  transition: 0.3s;
}

.modal-content{
  border-style: none !important;
}

.saniba_modal-content-top{
  justify-content: flex-start; align-items: flex-start; gap: 162px; display: inline-flex
}
.NavbarButton{
  background-color: var(--white) !important;
}