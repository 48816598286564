.banner-section {
  margin-top: 20px;
}

.banner-wrapper {
  position: relative;
}

.banner-image {
  width: 100%;
  height: 480px;
  /* Set the height to match the aspect ratio of your background image */
  background-image: url('../../../assets/images/welcomebg.png');
  /* Replace with your actual image URL */
  background-size: cover;
  /* Ensures the image covers the entire div */
  background-position: center;
  /* Centers the image within the div */
  border-radius: 60px;
  /* Adds border radius to the background image */
  margin: 20px auto;
  /* Adds margin to the top, left, and right */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  /* Adds shadow to the banner image */
}

.banner-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
}

.banner-title {
  font-size: 3rem;
  /* Large font size for the title */
  margin-bottom: 10px;
  text-shadow: 2px 2px 40px rgba(0, 0, 0, 0.7);
}

.banner-subtitle {
  font-size: 1.5rem;
  /* Slightly smaller font size for the subtitle */
  text-shadow: 1px 1px 30px rgba(0, 0, 0, 0.5);
}

.logo-wrapper {
  bottom: -30px;
  left: 50%;
  transform: translateX(-50%);
  padding-top: 14px;
  background-color: var(--background);
  border-radius: 50%;
  margin-top: 10px;
  /* Adds margin from every side */
}

.banner-logo {
  width: 80px;
  /* Adjust the logo size */
  height: 80px;
  /* Adjust the logo size */
  border-radius: 50%;
  /* Ensures the logo itself is circular */
}