.WelcomePage{
    background-color: var(--background) !important;
    background-image: url('../../assets/images/bg.png');
    background-repeat: no-repeat;    
    background-position: center top;
    background-size: 120%;
    background-attachment: fixed;
  }
    
  .WelcomeProfiles {
    background-color: var(--background) !important;
    background-image: url('../../assets/images/bgzone.png');
    margin: 0;
    padding: 0;
    height: 100vh;
    color: var(--white);
    background-size: 30%;
    background-position: left center; /* Set background image to the left horizontally and center vertically */
    background-repeat: no-repeat;
  }

.NavbarSaniba{
    width: 100%;
    z-index: 1000; /* Adjust the z-index value as needed */
    position: sticky;
    top: 0;
}

.title-site{
    width: 919px; text-align: center; color: white; font-size: 48px;
}

.description-site{
    font-size: 26px; font-family: Plus Jakarta Sans; word-wrap: break-word; color: white;
}


/*PRESENTATION*/

.brand-presentation{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}


.brand-presentation-logo{
    width: 5rem;
}

.brand-presentation-text {
    text-align: center;
    width: 80%;
    font-size: 52px;
    text-shadow: 0px 0px 20px #17171775;
    margin-top: -5rem;
}

.brand-presentation-text-white{
    color: white;
}
.brand-presentation-text > h1{
    width: 100%;
}
.brand-presentation-text > h2{
    font-weight: 200 !important;
}

.brand-presentation-text-green{
    color: #9EC208;
}

@media only screen and (max-width: 767px) {
    
    .brand-presentation-text > h1{
        font-size: 32px;
    }
    .brand-presentation-logo{
        width: 4rem;
        margin-top: -2rem;
    }    
}

@media only screen and (min-width: 767px) and (max-width: 992px) {
    
    .brand-presentation-text > h1{
        font-size: 40px;
    }
    .brand-presentation-logo{
        margin-top: -2rem;
    }    
    .WelcomePage{
        background-image: url('../../assets/images/bg.png');
        background-repeat: no-repeat;    
        background-position: center;
        background-size: 120%;
        background-attachment: fixed;
      }
        
}