@font-face {
    font-family: plusj;
    src: url(assets/fonts/PlusJakartaSans-Variable.ttf);
  }
  
@font-face {
    font-family: montserrat-semibold;
    src: url(assets/fonts/Montserrat-VariableFont_wght.ttf);
  }

@font-face {
  font-family: chillax;
  src: url(assets/fonts/Chillax-Variable.ttf);
}

.sidenav__button, .sidenav__button__selected, .logout__button{
  font-family: plusj;
  font-weight: 500;
  word-wrap: break-word;
  letter-spacing: 0.16rem;
  text-shadow: 0px 0px 20px #171717;
}

.navbar-brand{
  font-family: chillax;
  font-weight: 600;
  font-style: normal;
  line-height: normal;
}

.login-modal-title{
  font-family: chillax;
  font-weight: 600;
  font-style: normal;
  line-height: normal;
}

.nav-item{
  font-family: plusj;
  font-weight: 500;
  word-wrap: break-word;
}

.brand-presentation-text-white{
  font-family: plusj; font-weight: 500; word-wrap: break-word;
}

.brand-presentation-text-green{
  font-family: chillax; font-weight: 700; word-wrap: break-word;
}

.title-plusj{
  font-family: Plus Jakarta Sans; font-weight: 500; word-wrap: break-word;
}

.description-plusj{
  font-family: Plus Jakarta Sans; font-weight: 600; word-wrap: break-word;
}

.Card1__Content__Title{
  font-family: plusj; font-weight: 800; letter-spacing: 3.20px; word-wrap: break-word; 
}
.Card2__Content__Title{
  font-family: plusj; font-weight: 800; letter-spacing: 3.20px; word-wrap: break-word; 
}
.Card1__Content__Paragraph{
  font-family: plusj; font-weight: 600; word-wrap: break-word; 
}

.Summary__Info__Title__Font{
  font-family: chillax;
  font-weight: 600;
}