.dashboard__left{
    color: var(--white);
    font-size: 26px; font-family: Plus Jakarta Sans; word-wrap: break-word; color: white;
}

.Summary__Info{
    display: flex;
    margin-top: 3rem;
}
.Summary__Info__Title{
    color: white;
    font-size: 20px;  
    font-family: Plus Jakarta Sans;
    font-weight: 400; 
    letter-spacing: 0.84; 
    word-wrap: break-word;
    display: flex;
    background-color: #171717;
    border-radius: 4rem;
    padding: 0.1rem 0.4rem;
    outline: 3px rgba(255, 255, 255, 0.5) solid; 
    stroke: var(--white);
    margin: 0rem 0.4rem;
    padding: 0.4rem 1rem;
    align-items: center;
}



.Summary__Info__Title__Button{
    color: var(--textBlack);
    font-size: 20px;  
    font-family: Plus Jakarta Sans;
    font-weight: 600; 
    letter-spacing: 0.84; 
    word-wrap: break-word;
    display: flex;
    background: linear-gradient(165deg, #00d7ff 0%, rgb(215 255 49) 100%);
    border-radius: 4rem;
    padding: 0.1rem 0.4rem;
    outline: 3px rgba(255, 255, 255, 0.5) solid; 
    stroke: var(--white);
    margin: 0rem 0.4rem;
    padding: 0.4rem 1rem;
    align-items: center;
}

.Summary__Info__Title__Button:hover {
    outline: 4px rgb(255, 255, 255) solid;
    transition: 0.3s;
    color: var(--textBlack);
    background: linear-gradient(165deg, white 1000%, rgba(255, 255, 255, 0) 100%);
}

.Summary__Info__Title__Button:hover:active {
    outline: 4px #D7FF30 solid;
    transition: 0.3s;
    background: linear-gradient(165deg, white 1000%, rgba(255, 255, 255, 0) 100%);
}
