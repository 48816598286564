.scrollable-container {
    height: 800px;
    /* Adjust the height as needed */
    overflow-y: auto;
    border: 1px solid #ddd;
    padding: 10px;
    margin: 20px 0;
    position: relative;
}

.post {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding: 10px;
    border-bottom: 1px solid #ddd;
    position: relative;
}

.image-container {
    flex: 1.5;
    padding-right: 20px;
}

.post-image {
    width: 100%;
    max-width: 1050px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    transition: box-shadow 0.3s ease;
}

.post-image:hover {
    box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.5);
}

.description-container {
    flex: 0.5;
}

.scroll-down-button {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background-color: #01272E;
    color: #fff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    transition: box-shadow 0.3s ease;
}

.scroll-down-button:hover {
    box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.5);
}

@media (max-width: 768px) {
    .post {
        flex-direction: column;
    }

    .image-container {
        padding-right: 0;
        padding-bottom: 10px;
    }
}