.random-image-section {
  height: 500px;
  /* Adjust height as needed */
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.main-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-image {
  width: 150%;
  height: 20rem;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.small-image {
  width: 100px;
  height: 100px;
  position: absolute;
  opacity: 0;
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.small-image.visible {
  opacity: 1;
  transform: scale(1);
}

.small-image:nth-child(2) {
  top: -50px;
  left: 150px;
}

.small-image:nth-child(3) {
  top: 50px;
  right: -50px;
}

.small-image:nth-child(4) {
  bottom: -50px;
  left: 50px;
}

.small-image:nth-child(5) {
  bottom: 50px;
  right: 150px;
}

.small-image:nth-child(6) {
  top: -50px;
  right: 50px;
}

/* Initial scale for small images */
.small-image {
  transform: scale(0.5);
}
