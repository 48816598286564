/*NAVBAR*/

.navbar{
    background-color: var(--background);
    color: #DCDCDC !important;
    backdrop-filter: blur(10px);
    padding: 2rem 0 2rem 0 !important;
    font-size: 22px; font-family: Plus Jakarta Sans; font-weight: 600; letter-spacing: 1.6px; word-wrap: break-word; display: flex; align-items: center;
}

a{
    color: #fff !important;
}

.navbar-toggler{
    height: 4rem;
    width: 100%;
    color: var(--white);
    border-style: none;
    padding: 0.2rem 0.5rem;
    background: linear-gradient(165deg, white 1000%, rgba(255, 255, 255, 0) 100%);
    box-shadow: 0px 14px 31px rgba(0, 0, 0, 0.25);
    border-radius: 32px;
    overflow: hidden;
    backdrop-filter: blur(72px);
    transition: all 0.3s ease;
    font-family: Plus Jakarta Sans;
    font-weight: 600;
    letter-spacing: 2.66px;
    font-size: 1.4rem;
    margin-top: 2rem;
}
.navbar-toggler:hover {
    outline: 4px rgb(255, 255, 255) solid;
    transition: 0.3s;
    color: var(--background);
}

.navbar-toggler:hover:active {
    outline: 4px #D7FF30 solid;
    transition: 0.3s;
    background: linear-gradient(165deg, white 0%, rgba(255, 255, 255, 0) 100%);
}

@media only screen and (max-width: 767px) {
    
    .navbar-collapse{
        display: flex;
        flex-direction: column;
    }

    .navbar-nav{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: 2rem;
    }

}

.MainItem{
    margin-right: 2rem;
}

.navbar-brand{
    margin-left: 2rem;
}

.navCollapsableItem{
    background-color: var(--white) !important;
    color: var(--background) !important;
}

.navCollapsableItem__LogOut:hover{
    cursor: pointer;
    background-color: rgba(202, 18, 18, 0.622) !important;
    color: var(--white) !important;
    transition: 0.3s;
}

.logo{
    width: 4rem !important; 
}