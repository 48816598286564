/*Bottom Navigation*/

.returnMainPage {
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Plus Jakarta Sans;
    font-weight: 600;
}

.returnMainPage__ArrowBack {
    border: #D7FF30 0.2rem;
    color: #D7FF30;
    font-size: 4;
    padding: 0.6rem;
    border-style: solid;
    border-radius: 9999px;
    stroke: #D7FF30;
    stroke-width: 1.2;
}
.returnMainPage__ArrowBack:hover {
    color: var(--background);
    stroke: var(--background);
    background-color: #D7FF30;
}

/*BOTTOM NAV BUTTONS*/

.BottomNav {
    color: var(--brand);
    width: 100%;
    padding: 1rem 1rem;
    border: 4px var(--brand) solid;
    box-shadow: 0px 14px 31px rgba(0, 0, 0, 0.25);
    border-radius: 36px;
    overflow: hidden;
    backdrop-filter: blur(4px);
    justify-content: center;
    align-items: center;
    gap: 10px;
    display: inline-flex;
    transition: all 0.3s ease;
}

.BottomNav:hover {
    background: var(--brand);
    color: var(--background);
    ;
    transition: 0.3s;
}

.BottomNav:hover:active {
    outline: 4px var(--brand) solid;
    transition: 0.3s;
}

.SelectionNavigationAlignment {
    margin-top: 1.8rem;
}

.SelectionDeleteAlignment {
    margin-top: 1.8rem;
}

.SelectionDeleteAlignment__Text {
    margin: 0.4rem 0.4rem;
}

/*PROJECT PAGE ALIGNMENT*/

.projectPage {
    display: flex;
    flex-direction: row;
}

.projectPage__navWraper {
    position: relative;
    flex: 0.2;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0;

}

.projectPage__Content {
    flex: 0.8;
}