.authenticate{
  font-family: Plus Jakarta Sans; font-weight: 600; font-size: 1rem;
}

.SignInSMSIcon{
  width: 3rem;
  color: var(--background);
  background-color: var(--white);
  border-radius: 100%;
  margin-right: 1rem;
  padding: 0.4rem;
}

.SignInSMSBtn{
  background: 0;
  border: 0;
  font-weight: bolder;
  color: var(--white);
  font-size: 1.2rem;
  font-family: Plus Jakarta Sans; font-weight: 600; 

}

.auth__more {
  padding: 20px;
  margin-right: 30px;
  margin-left: 30px;
  margin-top: 13px;
  text-align: center;
}

.auth__more > span {
  font-weight: 500;
  font-size: 14px;
}

.auth__more > span > div >button {
  background: 0;
  border: 0;
  font-weight: bolder;
  color: var(--white);
  font-size: 1.2rem;
  font-family: Plus Jakarta Sans; font-weight: 600; 
}
.SignInGoogleAlignment{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}
.SignInGoogleAlignment:hover{
  cursor: pointer;
}

.SignInGoogleLogo{
  width: 3rem;
  background-color: var(--white);
  border-radius: 100%;
  margin-right: 1rem;
}


.authButton{
  height: 4rem;
  width: 100%;
  color: var(--white);
  border-style: none;
  padding: 0.2rem 0.5rem;
  box-shadow: 0px 14px 31px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  overflow: hidden;
  backdrop-filter: blur(72px);
  transition: all 0.3s ease;
}
.authButton:hover {
  outline: 4px rgb(255, 255, 255) solid;
  transition: 0.3s;
  color: var(--background);
  background: linear-gradient(165deg, white 1000%, rgba(255, 255, 255, 0) 100%);
}

.authButton:hover:active {
  outline: 4px #D7FF30 solid;
  transition: 0.3s;
  background: linear-gradient(165deg, white 0%, rgba(255, 255, 255, 0) 100%);
}

.authInput > div > ul{
  color: var(--background) !important;
  font-family: Plus Jakarta Sans; font-weight: 600; font-size: 1rem;
}

.authInput > input{
  height: 4rem;
  width: 100%;
  box-sizing: border-box; /* Include padding and border in the width */
  margin-bottom: 10px; /* Adjust spacing between input fields */
  border-style: none;
  padding: 0.2rem 0.5rem;
  background: linear-gradient(165deg, white 0%, rgba(255, 255, 255, 0) 100%);
  box-shadow: 0px 14px 31px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  overflow: hidden;
  backdrop-filter: blur(72px);
  transition: all 0.3s ease;
}

.authInput:hover > input{
  outline: 4px rgb(255, 255, 255) solid;
  transition: 0.3s;
}

.authInput:hover:active > input{
  outline: 4px #D7FF30 solid;
  transition: 0.3s;
}